import React, { useEffect, useState } from "react";
import me from "../../assets/images/Florentpro.jpg";
import "./Hero.scss";

const Hero = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [typedText, setTypedText] = useState("");

  useEffect(() => {
    setIsVisible(true);
    let index = 0;
    let text = "Je suis Développeur Full Stack et Data Analyst";

    const typeWriter = setInterval(() => {
      if (index < text.length) {
        setTypedText((prev) => text.slice(0, index + 1));
        index++;
      } else {
        clearInterval(typeWriter);
      }
    }, 100);

    return () => clearInterval(typeWriter);
  }, []);

  const skills = [
    {
      category: "Langages",
      icon: "💻",
      items: ["JavaScript/TypeScript", "Python", "PHP", "SQL"],
    },
    {
      category: "Big Data",
      icon: "📊",
      items: ["Hadoop", "Spark", "Airflow", "Hive"],
    },
    {
      category: "Analyse de données",
      icon: "📈",
      items: ["Pandas", "NumPy", "Matplotlib", "Power BI", "Tableau"],
    },
    {
      category: "Outils",
      icon: "🛠️",
      items: ["Docker", "Git", "Figma", "Jupyter"],
    },
  ];

  return (
    <section className="hero" id="accueil">
      <div className={`hero__content ${isVisible ? "visible" : ""}`}>
        <div className="hero__text">
          <p className="hero__text-greeting">
            <span>BONJOUR</span>, JE M'APPELLE <span>FLORENT GATTI</span>
          </p>
          <h1 className="hero__text-title">{typedText}</h1>
          <p className="hero__text-description">Développeur Full Stack et Data Analyst, j’allie expertise technique et esprit analytique pour concevoir des solutions performantes répondant aux besoins métiers. Fort d’une expérience en développement web et en manipulation de données massives, je transforme les défis techniques en opportunités stratégiques pour les entreprises.</p>
          <p className="hero__text-description">Mes compétences couvrent un large éventail de technologies modernes, allant du développement frontend et backend à l’analyse avancée des données. Avec une approche centrée sur la valeur, je m'engage à proposer des solutions innovantes, efficaces et adaptées aux enjeux des organisations.</p>
        </div>
        <div className="hero__image">
          <img src={me} alt="Florent Gatti" />
        </div>
      </div>
      <div className="hero__skills">
        <h2 className="hero__skills-title">Compétences Clés</h2>
        <div className="hero__skills-grid">
          {skills.map((skill, index) => (
            <div className="hero__skills-card" key={index}>
              <div className="hero__skills-icon">{skill.icon}</div>
              <h3>{skill.category}</h3>
              <ul>
                {skill.items.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hero;
